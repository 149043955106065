@import "../../assets/styles/variables";

.navigation {
  position: relative;
  z-index: 99;
  background-color: white;
  height: 70px;
  display: block;
  font-size: 1rem;

  &.showSearch {
    background-color: #dce3e7;

    .logo,
    .menu {
      transform: translateY(-200px);
      transition: transform 0.3s;
    }

    .search .icon.back {
      display: block;
    }
  }

  .nav-link {
    color: inherit;
    padding: 0;
    padding-left: 1.5rem;

    &:after {
      display: none;
    }
  }

  .dropdown-item {
    color: inherit;
    text-align: center;
    padding: 11px;

    &:focus,
    &:hover,
    &:active {
      color: inherit;
      background-color: rgba(250, 250, 250, 0.95);
    }
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 44px, 0px) !important;
    padding: 0;
  }

  @include breakpoint(untilTablet) {
    display: none;
  }

  ul {
    list-style-type: none;
  }

  .container {
    padding: 0;
  }

  .row {
    align-items: center;
  }

  .search {
    padding-left: 90px;
    position: relative;

    .form-control {
      border: none;
      padding-left: 2rem;
      background-color: transparent;

      &:focus {
        box-shadow: none;
      }
    }

    .icon {
      position: absolute;
      left: 5.5rem;
      top: 50%;
      transform: translateY(-50%);

      &.back {
        left: auto;
        right: 2rem;
        z-index: 4;
        cursor: pointer;
        display: none;
      }

      img {
        width: 20px;
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    height: 64px;
    img {
      height: 64px;
    }
  }

  .menu {
    padding-right: 90px;
    display: flex;
    justify-content: flex-end;
    ul {
      display: flex;

      li {
        margin-left: 30px;
        font-size: 1.1rem;
      }
    }
  }
}

.headerSmall {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: 40px;
  background-color: white;

  .container {
    padding: 0 10px;
    position: relative;
    height: 100%;
    img {
      width: auto;
      height: 20px;
    }

    > div {
      transition: all 0.2s ease;
    }

    .iconSearch {
      margin-left: 20px;
      transform: translateY(100%);
      opacity: 0;

      img {
        height: 1rem;
      }
    }

    .iconMenu {
      margin-right: 20px;
      opacity: 0;
      transform: translateY(100%);
      cursor: pointer;
    }
    .logo {
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translate(-50%, -150%);
      img {
        height: 38px;
      }
    }
  }

  @include breakpoint(untilTablet) {
    .container {
      .iconMenu,
      .iconSearch {
        transform: translateY(0);
        opacity: 1;
      }

      .logo {
        transform: translate(-50%, -50%);
      }
    }
  }
  &.active {
    .container {
      .iconMenu,
      .iconSearch {
        transform: translateY(0);
        opacity: 1;
      }

      .logo {
        transform: translate(-50%, -50%);
      }
    }
  }
}

.gnav {
  position: fixed;
  top: 0;
  right: -210px;
  bottom: 0;
  z-index: 100;
  width: 200px;
  -webkit-box-shadow: -2px 0 4px 2px rgb(0 0 0 / 20%);
  box-shadow: -2px 0 4px 2px rgb(0 0 0 / 20%);
  background-color: #fff;
  margin-right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 20px 0;
  transition: all 0.2s ease;
  overflow: auto;

  .item_list {
    > a {
      font-weight: 400;
      font-size: 1.13em;
      display: block;
      margin-top: 25px;
      border-bottom: 1px solid #efefef;
      padding: 0 20px;
      padding-bottom: 5px;
    }
  }

  .item_sublist {
    padding: 8px 20px;
  }
}
