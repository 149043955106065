@import "../../assets/styles/variables";

.cart {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 450px;
  justify-content: center;
  z-index: 20;

  @include breakpoint(untilTablet) {
    width: 90%;
    min-width: 90%;
    right: 1.5rem;
  }

  .cartWrapper {
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 1rem;
    max-width: 450px;
    min-width: 300px;

    @include breakpoint(untilTablet) {
      max-width: 100%;
      min-width: 100%;
    }

    .items {
      min-height: 300px;
    }

    .item {
      margin-bottom: 1.5rem;

      .info {
        .title {
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .image {
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .remove {
      cursor: pointer;
    }
  }

  .cartButton {
    display: inline-block;
    position: relative;
    background-color: $primary;
    padding: 0.5rem;
    border-radius: 50%;
    font-size: 2rem;
    height: 4rem;
    width: 4rem;
    text-align: center;
    box-shadow: 0 0 5px 5px rgba($color: #969696, $alpha: 0.2);
    color: white;
    .cartTotal {
      position: absolute;
      font-size: 0.95rem;
      top: -1rem;
      right: -1rem;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 50%;
      background-color: #d2e7f1;
      color: black;
    }
  }
}
