@import "../../assets/styles/variables";

.container {
  padding: 5vh 1rem;

  .slick-slide img {
    margin: auto;
  }
  .back {
    font-size: 1rem;
    margin-bottom: 1rem;
    display: block;
  }

  .product {
    border: 1px solid lightgray;

    .skeletonWrapper {
      padding: 2rem;
    }

    .productWrapper {
      padding: 2rem;
      padding-top: 0;

      .serviceType {
        margin-left: -2rem;
        padding: 0.5rem 2rem;
        padding-bottom: 0.75rem;
        font-size: 1rem;
        margin-bottom: 1.5rem;
        border: 1px solid lightgray;
        color: $primary;
        border-left: 0;
        border-top: 0;
        font-family: "Crimson Pro", serif;
        display: inline-block;
      }

      @include breakpoint(untilTablet) {
        .serviceType {
          font-size: 1rem;
          margin-left: -1rem;
          padding-left: 1rem;
        }
      }

      .title {
        text-align: center;
      }

      .carousel {
        text-align: center;
        margin: 2rem 0;
        margin-bottom: 4rem;

        @include breakpoint(untilTablet) {
          > img {
            width: 100%;
          }
        }

        .slick-dots {
          bottom: -40px;
        }

        .image {
          max-height: 300px;
          width: auto;
        }
      }

      .checkPrice {
        padding: 1rem;
        background-color: $primary;
        color: white;
        h4 {
          margin-bottom: 1rem;
        }

        .formCategories {
          position: relative;
          .icon {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: black;
          }
          select {
            padding: 0.75rem 3rem;
            font-weight: bold;
          }
        }

        .btn {
          font-size: 1.15rem;
          padding: 0.5rem 2rem;
          background-color: transparent;
          color: white;
          border: 1px solid white;
        }

        .formDate {
          margin-right: 0.5rem;
          max-width: 300px;
          color: black;
        }

        .formIcon {
          position: relative;
          margin-right: 0.5rem;
          max-width: 102px;

          .icon {
            position: absolute;
            left: 0.5rem;
            top: 38%;
            transform: translateY(-50%);
            color: black;

            img {
              height: 20px;
              width: auto;
            }
          }

          @include breakpoint(untilTablet) {
            margin-bottom: 0 !important;
          }

          input {
            padding-left: 2rem;
          }
        }
      }

      .sectionTitle {
        border-bottom: 1px solid $primary;
        margin-bottom: 1rem;

        span {
          padding: 0.5rem 3rem;
          padding-left: 1rem;
          font-size: 1.75rem;
          font-family: "Crimson Pro", serif;
          text-align: center;
          display: block;
        }
      }

      tr {
        border-bottom: 1px solid grey;

        td {
          padding: 1rem 0.5rem;
        }
      }
    }

    @include breakpoint(untilTablet) {
      .skeletonWrapper,
      .productWrapper {
        padding: 1rem;
      }

      .productWrapper {
        padding-top: 0;

        .serviceType {
          margin-left: -1rem;
          padding: 0.5rem 1rem;
        }

        .carousel {
          .image {
            max-width: 100%;
          }
        }
      }
    }
  }
}
