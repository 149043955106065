@import "../../assets/styles/variables";

.footer {
  padding-top: 45px;
  background-color: $secondary;

  .container {
    padding: 0;
  }

  .breadcrumbs {
    padding: 15px 0;
    margin: 0 10px;
    border-bottom: 1px solid #d4d4d4;
    line-height: 1;
    font-size: 0.93em;

    .breadcrumbs_lists {
      letter-spacing: -0.4em;

      .item_list {
        display: inline-block;
        letter-spacing: 0.06em;
        margin-left: 15px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .footer_menu {
    padding: 25px 0;
    margin: 0 10px;
    overflow: hidden;

    .fnav {
      float: left;

      .fnav_column {
        float: left;
        width: 150px;
        padding-right: 20px;
        .fnav_head {
          font-weight: 400;
          margin-bottom: 15px;
        }

        .fnav_lists .item_list {
          color: #666;
          margin-top: 5px;
        }

        @include breakpoint(untilTablet) {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          width: 50%;
          padding-right: 10px;
          margin-bottom: 15px;

          .fnav_head {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer_modal_send {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: none;
    padding: 15px;
    border-radius: 3px;
    background-color: #dce3e7;
  }

  .footer_modal_complete {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: none;
    width: 100%;
    height: 195px;
    padding: 15px;
    border-radius: 3px;
    background-color: #dce3e7;
  }

  .footer_other {
    float: right;
    position: relative;
    width: 300px;

    @include breakpoint(untilTablet) {
      float: left;
      width: 50%;
    }

    .footer_sns {
      margin-top: 25px;

      .item_lists .item_list {
        float: left;
        width: 40px;
        margin-right: 10px;

        @include breakpoint(untilTablet) {
          width: 33px;
          margin-right: 4px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .footer_address {
    padding: 15px 0;
    margin: 0 10px;
    border-top: 1px solid #d4d4d4;
    overflow: hidden;

    .footer_links {
      float: left;
      margin-bottom: 15px;
      .item_link {
        float: left;
        margin-right: 20px;

        @include breakpoint(untilTablet) {
          margin-right: 0;
          width: 50%;
          margin-bottom: 5px;
        }
      }
    }

    .item_copy {
      float: right;
      color: #ababab;

      address {
        margin-bottom: 0;
      }

      @include breakpoint(untilTablet) {
        float: none;
        text-align: center;
        padding-top: 15px;
      }
    }
  }
}
