.filterWrapper {
  margin: 2rem 0;

  .form-control,
  .form-select {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid lightgray;

    &:focus {
      box-shadow: none;
    }
  }

  .btn {
    font-weight: bold;
  }

  .react-calendar {
    width: 200px;
    font-size: 10px;
    position: absolute;

    &.hide {
      display: none;
    }
  }

  .react-calendar__navigation button {
    min-width: 24px;
  }

  .react-calendar__tile {
    line-height: 10px;
  }

  .form-control[readonly] {
    background-color: white;
    cursor: pointer;
  }
}